import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c('div',{staticClass:"text-h4 mb-3"},[_vm._v("New Relocation")]),_c(VStepper,{attrs:{"alt-label":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"complete":_vm.step > 1,"step":"1"}},[_vm._v(" Where ")]),_c(VDivider),_c(VStepperStep,{attrs:{"complete":_vm.step > 2,"step":"2"}},[_vm._v(" Who & What ")]),_c(VDivider)],1),_c(VStepperItems,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c(VStepperContent,{attrs:{"step":"1"}},[_c('ReloStepGeneral'),_c('StepperButtons',{attrs:{"disabled":!_vm.formValid,"validateForm":_vm.validateForm}})],1),_c(VStepperContent,{attrs:{"step":"2"}},[_c('ReloStepDetail'),_c('StepperButtons',{attrs:{"disabled":!_vm.formValid,"validateForm":_vm.validateForm}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }