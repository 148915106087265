<template>
    <v-container>
        <!--
        <division-select
            v-model="relocation.division"
            :items="divisionList"
            :rules="$rules.required"
        />
        <v-row class="mb-3">
            <v-col>
                <SearchPeople
                    label="Search people"
                />
            </v-col>
        </v-row>
        --><div>
        <v-expand-transition>
            <div v-show="isEmptyObj(relocation.locations)">
                <v-alert
                    type="info"
                    outlined
                    dense
                >
                    Multiple <strong>from</strong> locations can be specified and each <strong>from</strong> location can correspond to multiple <strong>to</strong> locations.
                </v-alert>
            </div>
        </v-expand-transition>
        </div>
        <div
            v-for="(item, key, index) in relocation.locations"
            :key="key"
        >
            <v-row no-gutters>
                <v-col>
                    <small class="overline grey--text text--darken-1">Relocation #{{ index + 1 }}</small>
                    <h5 class="mt-n1 text-h5">From {{ key }} to:</h5>
                </v-col>
                <v-col class="d-flex justify-end align-center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon
                                color="red lighten-2"
                                v-on="on"
                                @click="removeFromLocation(key)"
                            >mdi-close-circle-outline</v-icon>
                        </template>
                        <span>Remove Relocation {{ key }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row v-if="Object.keys(relocation.locations[key].to).length > 0">
                <v-col>
                    <v-chip
                        v-for="(loc, i) in Object.keys(relocation.locations[key].to)"
                        :key="i"
                        close
                        @click:close="removeToLocation(key, loc)"
                        class="ma-1"
                    >
                        <v-icon outlined left>mdi-office-building</v-icon>
                        <strong>{{ loc }}</strong>
                    </v-chip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="!showToLocations.includes(key)"
                                    v-on="on"
                                    @click="showToLocation(key)"
                                >
                                    mdi-plus-box
                                </v-icon>
                            </template>
                            <span>Add Location</span>
                        </v-tooltip>
                        
                </v-col>
            </v-row>
            <v-row v-if="showToLocations.includes(key) || Object.keys(relocation.locations[key].to).length === 0">
                <v-col>
                    <v-card>
                        <LocationInput
                            btnLabel="Add To Location"
                            :payload="{key: key}"
                            containerClass="pl-4 pr-4 pt-0 pb-0"
                            @location="addToLocation"
                        />
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <v-divider v-if="Object.keys(relocation.locations).length !== 0" class="mt-3 mb-5"></v-divider>
        <template v-if="showAddFromLocation || Object.keys(relocation.locations).length === 0">
            <v-card>
                <v-card-title class="pb-0 text-h5">Add From Location</v-card-title>
                <LocationInput
                    containerClass="pl-4 pr-4 pt-0 pb-0"
                    @location="addFromLocation"
                />
            </v-card>
        </template>
        <template v-else>
            <v-row no-gutters>
                <v-col>
                    <v-btn
                        @click="showAddFromLocation = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        Add Relocation
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>
<script>
    import { mapGetters } from 'vuex';
    //import DivisionSelect from "@/components/population/DivisionSelect";
    //import SearchPeople from '@/components/population/SearchPeople';
    import LocationInput from '@/components/ops/LocationInput';

    export default {
        name: 'ReloStepGeneral',
        components: {
            //DivisionSelect,
            LocationInput,
            //SearchPeople
        },
        data() {
            return {
                showAddFromLocation: true,
                showToLocations: [],
                fromLocation: {},
                toLocation: {}
            }
        },
        computed: {
            ...mapGetters({
                divisionList: 'population/getDivisionList',
                relocation: 'ops/getRelocation'
            })
        },
        methods: {
            validateForm() {
                return this.valid;
            },
            addFromLocation(loc) {
                const key = `${loc.building.short_name}-${loc.floor}-${loc.room}`;
                this.$set(this.relocation.locations, key, {
                    to: {}
                });
                this.showAddFromLocation = false;
            },
            addToLocation(loc, payload) {
                const key = `${loc.building.short_name}-${loc.floor}-${loc.room}`;

                const fromLoc = this.relocation.locations[payload.key];

                this.$set(fromLoc.to, key, {});

                // remove from showToLocations
                this.showToLocations = this.showToLocations.filter(x => x !== payload.key);
            },
            removeToLocation(fromLoc, toLoc) {
                this.$delete(this.relocation.locations[fromLoc].to, toLoc);
            },
            removeFromLocation(key) {
                this.$delete(this.relocation.locations, key);
            },
            showToLocation(key) {
                if (!this.showToLocations.some(x => x === key)) this.showToLocations.push(key);
            }
        }
    }
</script>
<style scoped>
.shakeIt {
    animation-name: shake;
    animation-duration: 5s;
    animation-timing-function: ease-in;
}
@keyframes shake {
    0% {left: 0}
    1% {left: -3px}
    2% {left: 5px}
    3% {left: -8px}
    4% {left: 8px}
    5% {left: -5px}
    6% {left: 3px}
    7% {left: 0}
}
</style>