<template>
    <v-container :class="containerClass">
        <v-row>
            <v-col>
                <v-autocomplete
                    label="Building"
                    :items="buildingList"
                    @change="chgBldg"
                    v-model="tmpLocation.building"
                    autocomplete="off"
                    item-text="name"
                    return-object
                    :rules="$rules.required"
                />
            </v-col>
            <v-col v-if="floors.length">
                <v-select
                    v-model="tmpLocation.floor"
                    :items="floors"
                    label="Floor"
                    :rules="$rules.required"
                />
            </v-col>
            <v-col v-else-if="tmpLocation.building && tmpLocation.building.name !== 'Remote / Other'">
                <v-text-field
                    v-model="tmpLocation.floor"
                    autocomplete="off"
                    label="Floor"
                    :rules="$rules.required"
                />
            </v-col>
            <v-col v-if="tmpLocation.building && tmpLocation.building.name !== 'Remote / Other'">
                <v-text-field
                    v-model="tmpLocation.room"
                    autocomplete="off"
                    label="Room"
                    @keyup.enter="emitOnEnter && emitLocation"
                    :rules="$rules.required"
                />
            </v-col>
            <slot name="rightAction"></slot>
        </v-row>
        <slot
            :emitLocation="emitLocation"
            name="bottomAction">
            <v-row justify="center" class="pb-8" v-if="!hideButtons">
                <v-btn
                    v-text="btnLabel"
                    @click="emitLocation"
                    :disabled="isValid"
                    small
                />
            </v-row>
        </slot>
    </v-container>
</template>
<script>
    import axios from 'axios';
    import { mapGetters } from "vuex";

    export default {
        name: 'LocationInput',
        props: {
            btnLabel: {
                type: String,
                default: 'Add From Location'
            },
            payload: {
                type: Object,
                default: null
            },
            hideButtons: {
                type: Boolean,
                default: false
            },
            location: {
                type: Object,
                default() {
                    return {}
                }
            },
            containerClass: {
                type: String,
                default: 'pa-0'
            },
            emitOnEnter: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                valid: false,
                floors: [],
                tmpLocation: {},
            }
        },
        computed: {
            ...mapGetters({
                buildingList: 'space/getBuildingList'
            }),
            isValid () {
                return Object.keys(this.tmpLocation).length !== 3;
            }
        },
        methods: {
            async chgBldg(bldg) {
                const response = await axios.get(`/space/building_floors/${bldg.id}/`);

                delete this.location.floor;
                delete this.location.room;

                if (response.data.floors.length) {
                    this.floors = response.data.floors
                    if (response.data.floors.length === 1) {
                        this.location.floor = response.data.floors[0]
                    }
                } else {
                    this.floors = []
                }

                this.$emit('changeBuilding');
            },
            emitLocation() {
                if (!this.isValid) {
                    this.$emit('location', this.tmpLocation, this.payload);
                    this.tmpLocation = {};
                }
            },
            emitLocationChange() {
                this.$emit('locationChange', this.tmpLocation);
            }
        },
        watch: {
            location: {
                handler() {
                    this.emitLocationChange();
                },
                deep: true
            }
        },
        mounted() {
            if (!this.buildingList.length) {
                this.$store.dispatch('space/loadBuildings');
            }
            this.tmpLocation = this.location;
        }
    }
</script>