<template>
    <v-container class="pl-8 pt-0 pb-0">
        <v-autocomplete
            v-model="relocation.locations[fromLocation].to[toLocation][field]"
            :items="items"
            chips
            :label="label"
            item-text="name"
            multiple
            deletable-chips
            return-object
            hide-details
            hide-no-data
            style="border: 1px solid #000"
        >
            <template v-slot:prepend-item>
                <v-list-item
                    ripple
                >
                    <span class="overline">{{ titleField }} in {{ fromLocation }}</span>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
                <!--
                <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                    >
                        <v-avatar left>
                            <v-icon>mdi-laptop</v-icon>
                        </v-avatar>
                        {{ data.item.name }}
                    </v-chip>
                </template>
                -->
                <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                    </template>
                    <template v-else>
                        <v-list-item-content>
                            <v-list-item-title v-html="data.item.name + (data.item.primary_user == null ? '' : ' - ' + data.item.primary_user)"></v-list-item-title>
                            <v-list-item-subtitle>
                                {{ data.item.spec }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </template>
            </v-autocomplete>

            <SearchEndpoints
                :label="`Search ${field} by tag #`"
                :endpointType="field"
                @input="addEndpoint($event)"
                clear-on-input
                style="border: 1px solid red"
            />

    </v-container>
</template>
<script>
    import {mapGetters} from 'vuex';
    import SearchEndpoints from "@/components/it/inventory/SearchEndpoints";

    export default {
        name: 'ReloITEquipmentField',
        components: {
            SearchEndpoints
        },
        props: {
            label: {type: String},
            items: {type: Array},
            field: {type: String},
            fromLocation: {type: String},
            toLocation: {type: String}
        },
        data() {
            return {
                fieldIcon: {
                    printers: 'printer',
                    computers: 'laptop'
                }
            }
        },
        computed: {
            ...mapGetters({
                relocation: 'ops/getRelocation'
            }),
            titleField() {
                return this.field.charAt(0).toUpperCase() + this.field.slice(1);
            }
        },
        methods: {
            addEndpoint (e) {
                const toLocation = this.relocation.locations[this.fromLocation].to[this.toLocation];
                if (!toLocation[this.field]) toLocation[this.field] = [];
                if (!toLocation[this.field].some(x => x.name === e.name)) {
                    this.items.push(e);
                    
                    toLocation[this.field].push(e);
                }
            }
        }
    }
</script>