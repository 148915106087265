import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{class:_vm.containerClass},[_c(VRow,[_c(VCol,[_c(VAutocomplete,{attrs:{"label":"Building","items":_vm.buildingList,"autocomplete":"off","item-text":"name","return-object":"","rules":_vm.$rules.required},on:{"change":_vm.chgBldg},model:{value:(_vm.tmpLocation.building),callback:function ($$v) {_vm.$set(_vm.tmpLocation, "building", $$v)},expression:"tmpLocation.building"}})],1),(_vm.floors.length)?_c(VCol,[_c(VSelect,{attrs:{"items":_vm.floors,"label":"Floor","rules":_vm.$rules.required},model:{value:(_vm.tmpLocation.floor),callback:function ($$v) {_vm.$set(_vm.tmpLocation, "floor", $$v)},expression:"tmpLocation.floor"}})],1):(_vm.tmpLocation.building && _vm.tmpLocation.building.name !== 'Remote / Other')?_c(VCol,[_c(VTextField,{attrs:{"autocomplete":"off","label":"Floor","rules":_vm.$rules.required},model:{value:(_vm.tmpLocation.floor),callback:function ($$v) {_vm.$set(_vm.tmpLocation, "floor", $$v)},expression:"tmpLocation.floor"}})],1):_vm._e(),(_vm.tmpLocation.building && _vm.tmpLocation.building.name !== 'Remote / Other')?_c(VCol,[_c(VTextField,{attrs:{"autocomplete":"off","label":"Room","rules":_vm.$rules.required},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.emitOnEnter && _vm.emitLocation}},model:{value:(_vm.tmpLocation.room),callback:function ($$v) {_vm.$set(_vm.tmpLocation, "room", $$v)},expression:"tmpLocation.room"}})],1):_vm._e(),_vm._t("rightAction")],2),_vm._t("bottomAction",function(){return [(!_vm.hideButtons)?_c(VRow,{staticClass:"pb-8",attrs:{"justify":"center"}},[_c(VBtn,{attrs:{"disabled":_vm.isValid,"small":""},domProps:{"textContent":_vm._s(_vm.btnLabel)},on:{"click":_vm.emitLocation}})],1):_vm._e()]},{"emitLocation":_vm.emitLocation})],2)}
var staticRenderFns = []

export { render, staticRenderFns }