import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('div',[_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEmptyObj(_vm.relocation.locations)),expression:"isEmptyObj(relocation.locations)"}]},[_c(VAlert,{attrs:{"type":"info","outlined":"","dense":""}},[_vm._v(" Multiple "),_c('strong',[_vm._v("from")]),_vm._v(" locations can be specified and each "),_c('strong',[_vm._v("from")]),_vm._v(" location can correspond to multiple "),_c('strong',[_vm._v("to")]),_vm._v(" locations. ")])],1)])],1),_vm._l((_vm.relocation.locations),function(item,key,index){return _c('div',{key:key},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('small',{staticClass:"overline grey--text text--darken-1"},[_vm._v("Relocation #"+_vm._s(index + 1))]),_c('h5',{staticClass:"mt-n1 text-h5"},[_vm._v("From "+_vm._s(key)+" to:")])]),_c(VCol,{staticClass:"d-flex justify-end align-center"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"red lighten-2"},on:{"click":function($event){return _vm.removeFromLocation(key)}}},on),[_vm._v("mdi-close-circle-outline")])]}}],null,true)},[_c('span',[_vm._v("Remove Relocation "+_vm._s(key))])])],1)],1),(Object.keys(_vm.relocation.locations[key].to).length > 0)?_c(VRow,[_c(VCol,[_vm._l((Object.keys(_vm.relocation.locations[key].to)),function(loc,i){return _c(VChip,{key:i,staticClass:"ma-1",attrs:{"close":""},on:{"click:close":function($event){return _vm.removeToLocation(key, loc)}}},[_c(VIcon,{attrs:{"outlined":"","left":""}},[_vm._v("mdi-office-building")]),_c('strong',[_vm._v(_vm._s(loc))])],1)}),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.showToLocations.includes(key))?_c(VIcon,_vm._g({on:{"click":function($event){return _vm.showToLocation(key)}}},on),[_vm._v(" mdi-plus-box ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Add Location")])])],2)],1):_vm._e(),(_vm.showToLocations.includes(key) || Object.keys(_vm.relocation.locations[key].to).length === 0)?_c(VRow,[_c(VCol,[_c(VCard,[_c('LocationInput',{attrs:{"btnLabel":"Add To Location","payload":{key: key},"containerClass":"pl-4 pr-4 pt-0 pb-0"},on:{"location":_vm.addToLocation}})],1)],1)],1):_vm._e()],1)}),(Object.keys(_vm.relocation.locations).length !== 0)?_c(VDivider,{staticClass:"mt-3 mb-5"}):_vm._e(),(_vm.showAddFromLocation || Object.keys(_vm.relocation.locations).length === 0)?[_c(VCard,[_c(VCardTitle,{staticClass:"pb-0 text-h5"},[_vm._v("Add From Location")]),_c('LocationInput',{attrs:{"containerClass":"pl-4 pr-4 pt-0 pb-0"},on:{"location":_vm.addFromLocation}})],1)]:[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VBtn,{on:{"click":function($event){_vm.showAddFromLocation = true}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Add Relocation ")],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }