<template>
    <div>
        <v-checkbox
            :label="label"
            class="mt-0"
            hide-details
            @change="loadITEquip($event, fromLocation, toLocation)"
        />
        <template v-if="reloITLocs.includes(fromLocation)">
            <ReloITEquipmentField
                field="computers"
                :label="`Computers moving from ${fromLocation} to ${toLocation}`"
                :items="fromLocEndpoints.computers"
                :fromLocation="fromLocation"
                :toLocation="toLocation"
            />
            <ReloITEquipmentField
                field="printers"
                :label="`Printers moving from ${fromLocation} to ${toLocation}`"
                :items="fromLocEndpoints.printers"
                :fromLocation="fromLocation"
                :toLocation="toLocation"
            />
        </template>
    </div>
</template>

<script>
    import axios from "axios";
    import ReloITEquipmentField from "@/components/ops/relocation/ReloITEquipmentField";

    import { mapGetters } from 'vuex';

    export default {
        name: 'ReloITEquipment',
        props: {
            label: {type: String},
            fromLocation: {type: String},
            toLocation: {type: String}
        },
        components: {
            ReloITEquipmentField
        },
        data() {
            return {
                reloITLocs: [],
                fromLocEndpoints: {
                    computers: [],
                    printers: []
                }
            };
        },
        computed: {
            ...mapGetters({
                relocation: 'ops/getRelocation',
            })
        },
        methods: {
            async loadFromLocEndpoints(fromLoc) {
                const response = await axios.get(`/inventory/load_endpoints_from_key/${fromLoc}/`);
                this.fromLocEndpoints = response.data.endpoints;
            },
            loadITEquip(event, fromLoc) {
                if (event) { // checked
                    this.reloITLocs.push(fromLoc);

                    // load endpoints
                    this.loadFromLocEndpoints(fromLoc);
            
                } else { // unchecked
                    this.reloITLocs = this.reloITLocs.filter(x => x !== fromLoc);
                }
            }
         }
    }
</script>