<template>
    <!--
        set the key to a truthy value of `relocation.locations` to ensure initial stepper
        appears given the programmatic instantiation
    -->
    <v-stepper
        v-model="locationStep"
        :key="!!Object.keys(relocation.locations).length"
        vertical
    >
        <template
            v-for="([fromLoc, toLoc], index) in Object.entries(relocation.locations)"
        >
            <v-stepper-step
                :key="`${fromLoc}--step`"
                :step="index + 1"
                class=""
                editable
            >
                <strong>{{ fromLoc }}</strong>
            </v-stepper-step>
            <v-stepper-content
                :key="`${fromLoc}--content`"
                :step="index + 1"
            >
                <v-list-item
                    v-for="(value, name, index2) in toLoc.to"
                    :key="index2"
                >
                    <v-list-item-icon class="ma-0 pa-0">
                        <v-icon>mdi-subdirectory-arrow-right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="mb-0 pa-0">
                        <strong class="mt-2 pa-0">{{ name }}</strong>
                        <v-container>
                            
                            <ReloITEquipment
                                label="IT equipment needs to be relocated"
                                :fromLocation="fromLoc"
                                :toLocation="name"
                            />
                            <v-checkbox
                                label="People need to be relocated"
                                hide-details
                            />
                            <v-checkbox
                                label="Telecom needs to be relocated"
                                hide-details
                            ></v-checkbox>
                        </v-container>
                    </v-list-item-content>
                </v-list-item>
            </v-stepper-content>
                    
        </template>
    </v-stepper>
</template>
<script>
import axios from "axios";
import { mapGetters } from 'vuex';

import ReloITEquipment from "@/components/ops/relocation/ReloITEquipment";

export default {
    name: "LocationSteps",
    props: {},
    components: {
        ReloITEquipment
    },
    data() {
        return {
            locationStep: 1
        };
    },
    computed: {
        ...mapGetters({
            relocation: 'ops/getRelocation'
        }),
        smartFromLocation() {
            return this.fromLocation();
        },
        hasActionSlot() {
            return this.$slots["actions"];
        },
    },
    methods: {
        async itEquipLoad(event, toLoc, fromLoc) {
            if (event) {
                // const url = `api/inventory/load_endpoints_from_key/${fromLoc}/`;
                const response = await axios.get(
                    `/inventory/load_endpoints_from_key/${fromLoc}/`
                );
                let endpoints = response.data.endpoints;
                this.$set(this.fromLocEndpoints, fromLoc, endpoints);
            } else {
                this.$set(this.fromLocEndpoints, fromLoc, {});
                // unchecked!
            }
            this.$set(toLoc, "show_it_equip", !toLoc.show_it_equip);
            //this.$set(loc, 'endpoints_')
        },
    },
};
</script>
