<template>
    <v-main>
        <v-container>
            <div class="text-h4 mb-3">New Relocation</div>
        
            <v-stepper alt-label v-model="step">
                <v-stepper-header>
                    <v-stepper-step :complete="step > 1" step="1">
                        Where
                    </v-stepper-step>
                    <v-divider />
                    <v-stepper-step :complete="step > 2" step="2">
                        Who &amp; What
                    </v-stepper-step>
                    <v-divider />
                    <!--
                    <v-stepper-step :complete="step > 4" step="4">
                        Review &amp; Submit
                    </v-stepper-step>
                    -->
                </v-stepper-header>
                <v-stepper-items>
                    <v-form
                        ref="form"
                        v-model="formValid"
                        lazy-validation
                    >
                        <v-stepper-content step="1">
                            <ReloStepGeneral />
                            <StepperButtons
                                :disabled="!formValid"
                                :validateForm="validateForm"
                            />
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <ReloStepDetail />
                            <StepperButtons
                                :disabled="!formValid"
                                :validateForm="validateForm"
                            />
                        </v-stepper-content>
                    </v-form>
                </v-stepper-items>
            </v-stepper>
        </v-container>
    </v-main>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api';

import ReloStepGeneral from '@/components/ops/relocation/ReloStepGeneral';
import ReloStepDetail from '@/components/ops/relocation/ReloStepDetail';
import StepperButtons from '@/components/StepperButtons';

export default {
    name: 'NewRelocation',
    components: {
        ReloStepGeneral,
        ReloStepDetail,
        StepperButtons
    },
    setup(_, { root, refs }) {

        onMounted(() => {
            root.$store.dispatch('population/loadDivisions');
            root.$store.dispatch('space/loadBuildings');
        });

        const formValid = ref(true);

        const relocation = computed(() => root.$store.getters['ops/getRelocation']);

        const step = computed(() => root.$store.getters['getStep']);

        const validateForm = () => {
            const isValid = refs.form.validate();
            let errorMsg;

            if (isValid) {

                if (!Object.keys(relocation.value.locations).length) {

                    // no entries
                    errorMsg = `Need to add at least one relocation`;

                } else if (Object.values(relocation.value.locations).some(x => Object.keys(x.to).length === 0)) {

                    // missing `to` location
                    errorMsg = `All relocations must include at least one destination location`;
                
                } else {

                    return isValid;

                }

                root.$store.commit('setErrorSnack', errorMsg);
                
                return false;
            }
        };

        return { formValid, step, validateForm };
    }
};
</script>