import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VStepper,{key:!!Object.keys(_vm.relocation.locations).length,attrs:{"vertical":""},model:{value:(_vm.locationStep),callback:function ($$v) {_vm.locationStep=$$v},expression:"locationStep"}},[_vm._l((Object.entries(_vm.relocation.locations)),function(ref,index){
var fromLoc = ref[0];
var toLoc = ref[1];
return [_c(VStepperStep,{key:(fromLoc + "--step"),attrs:{"step":index + 1,"editable":""}},[_c('strong',[_vm._v(_vm._s(fromLoc))])]),_c(VStepperContent,{key:(fromLoc + "--content"),attrs:{"step":index + 1}},_vm._l((toLoc.to),function(value,name,index2){return _c(VListItem,{key:index2},[_c(VListItemIcon,{staticClass:"ma-0 pa-0"},[_c(VIcon,[_vm._v("mdi-subdirectory-arrow-right")])],1),_c(VListItemContent,{staticClass:"mb-0 pa-0"},[_c('strong',{staticClass:"mt-2 pa-0"},[_vm._v(_vm._s(name))]),_c(VContainer,[_c('ReloITEquipment',{attrs:{"label":"IT equipment needs to be relocated","fromLocation":fromLoc,"toLocation":name}}),_c(VCheckbox,{attrs:{"label":"People need to be relocated","hide-details":""}}),_c(VCheckbox,{attrs:{"label":"Telecom needs to be relocated","hide-details":""}})],1)],1)],1)}),1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }