import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"pl-8 pt-0 pb-0"},[_c(VAutocomplete,{staticStyle:{"border":"1px solid #000"},attrs:{"items":_vm.items,"chips":"","label":_vm.label,"item-text":"name","multiple":"","deletable-chips":"","return-object":"","hide-details":"","hide-no-data":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VListItem,{attrs:{"ripple":""}},[_c('span',{staticClass:"overline"},[_vm._v(_vm._s(_vm.titleField)+" in "+_vm._s(_vm.fromLocation))])]),_c(VDivider,{staticClass:"mt-2"})]},proxy:true},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c(VListItemContent,{domProps:{"textContent":_vm._s(data.item)}})]:[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(data.item.name + (data.item.primary_user == null ? '' : ' - ' + data.item.primary_user))}}),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(data.item.spec)+" ")])],1)]]}}]),model:{value:(_vm.relocation.locations[_vm.fromLocation].to[_vm.toLocation][_vm.field]),callback:function ($$v) {_vm.$set(_vm.relocation.locations[_vm.fromLocation].to[_vm.toLocation], _vm.field, $$v)},expression:"relocation.locations[fromLocation].to[toLocation][field]"}}),_c('SearchEndpoints',{staticStyle:{"border":"1px solid red"},attrs:{"label":("Search " + _vm.field + " by tag #"),"endpointType":_vm.field,"clear-on-input":""},on:{"input":function($event){return _vm.addEndpoint($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }