<template>
    <v-autocomplete
        v-model="model"
        item-text="full_name"
        :loading="showLoader && loading"
        :items="items"
        :label="label"
        :rules="rules"
        :search-input.sync="search"
        :prepend-inner-icon="prependInnerIcon"
        :rounded="rounded"
        :outlined="outlined"
        :dense="dense"
        :hide-details="hideDetails"
        @input="handleInput($event)"
        ref="epSearch"
        clearable
        return-object
        hide-no-data
    >
        <template v-slot:selection="{ item }">
            {{ item.full_name }}
        </template>
    </v-autocomplete>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api';
import axios from 'axios';

export default {
    name: 'SearchEndpoints',
    props: {
        endpointType: { type: String },
        label: { type: String },
        multiple: { type: Boolean, default: false },
        chips: { type: Boolean, default: false },
        clearOnInput: { type: Boolean, default: false },
        minChar: { type: Number, default: 2 },
        prependInnerIcon: { type: String, default: undefined },
        rounded: { type: Boolean, default: false },
        outlined: { type: Boolean, default: false },
        dense: { type: Boolean, default: false },
        withUser: { type: Boolean, default: false },
        withUserLocation: { type: Boolean, default: false },
        showLoader: { type: Boolean, default: true },
        hideDetails: { type: Boolean, default: true },
        rules: {
            type: Array,
            default() {
                return []
            }
        },
        hasExistingValue: {
            type: Object, 
            default(){
                return null
            }
        },
        value: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    emits: ['input'],

    setup(props, { root, emit, refs }) {
        const model = ref(null);
        const loading = ref(false);
        const items = ref([]);
        const search = ref(null);
        const select = ref(null);

        if (props.hasExistingValue) {
            items.value.push(props.hasExistingValue);
            model.value = props.hasExistingValue;
        }

        const querySelections = async (v) => {
            loading.value = true;

            let url = `/inventory/endpoint_search/${v}/?simple=1`;

            if (props.endpointType) {
                url += `&type=${props.endpointType.replace(/desktops|laptops/, 'computers')}`;
            }
            
            if (props.withUser) url += '&with_user=1';
            if (props.withUserLocation) url += '&with_user_location=1';

            const response = await axios.get(url);
            items.value = response.data.endpoints;
            loading.value = false;
        };

        const handleInput = (evt) => {
            if (props.clearOnInput) {
                root.$nextTick(() => {
                    model.value = null;
                });
            }
            emit('input', evt);
            refs.epSearch.blur();
        }

        watch(search, (val) => {
            val && val !== select.value && val.length > props.minChar && querySelections(val);
        });


        onMounted(() => {
            if (props.value && props.value.pk) {
                items.value = [props.value];
                model.value = items.value[0];
            }
        });

        return {
            model,
            loading,
            search,
            items,
            select,
            handleInput,
            querySelections
        }
    }
};
</script>