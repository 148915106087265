<template>
    <v-container>
        <ReloLocSubSteps />
    </v-container>
</template>
<script>
    //import {mapGetters} from 'vuex';
    import ReloLocSubSteps from '@/components/ops/relocation/ReloLocSubSteps';

    export default {
        name: 'ReloStepDetail',
        components: {
            ReloLocSubSteps
        },
        computed: {
            /*
            ...mapGetters({
                relocation: 'ops/getRelocation'
            })
            */
        },
        data() {
            return {}
        },
        methods: {},
    }
</script>