<template>
    <v-container>
        <v-row>
            <v-col class="text-center">
                <v-btn
                    v-if="step > 1"
                    class="mr-3"
                    @click="prevClickWrap"
                >Back</v-btn>
                <v-btn
                    v-if="showNext"
                    color="primary"
                    @click="nextClickWrap"
                    :disabled="disabled || internalNextDisable"
                >{{ nextButtonText === undefined ? 'Continue' : nextButtonText }}</v-btn>
                <slot name="right"></slot>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "StepperButtons",
    computed: {
        ...mapGetters({
            step: "getStep",
        }),
    },
    props: {
        disabled: Boolean,
        altClick: Function,
        validateForm: {
            type: Function,
            required: false
        },
        nextButtonText: String,
        altStep: Number,
        showNext: {
            type: Boolean,
            default: true
        },
        vuex: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            internalNextDisable: false,
        };
    },
    methods: {
        ...mapActions(["setNextStep", "setPrevStep"]),
        scrollTop() {
            this.$vuetify.goTo(0);
            /*
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            */
        },
        
        async nextClickWrap() {

            // handle form validation
            if (this.validateForm && !this.validateForm()) {
                return;
            }

            this.internalNextDisable = true;

            if (this.altClick) {
                await this.altClick();
            }

            this.$emit("nextStep");
            if (this.vuex) this.setNextStep();

            this.$nextTick(() => {
                this.scrollTop();
            });

            this.internalNextDisable = false;
        },

        prevClickWrap() {
            this.$emit("prevStep");
            if (this.vuex) this.setPrevStep();
            this.scrollTop();
        },
    },
};
</script>